import * as React from "react"
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import AccordionIcons from "./AccordionIcons";
import plusIcon from '../images/newimage/plus.png';
import {configData} from "../services/config";

const FaqDetail = () => {
    const imgAttrs = {src: plusIcon, alt: 'Accordion Close'}
    return (<>
            <div>
                <Accordion className={'faqAccordionSection'} onChange={((e) => AccordionIcons(e))}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>What is ARUZY? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>ARUZY is a subscription-based lifestyle platform for people looking
                                for life-affirming experiences. As an ARUZY member, you’ll become
                                part of our community of people who love to be sociable and discover
                                new places. We offer membership discounts across our partner brands,
                                from zoo experience days to dining offers and rainy days out. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>What’s included in an ARUZY membership? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>You get discounts at all our partner brands across restaurants,
                                retail and experiences. You’ll also be the first in the queue to buy
                                tickets for our member’s events. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>Will you add more brands? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>We’re continually speaking with new partners and adding them to our
                                website. Sign up to our newsletter to find out what’s new each month
                                or log in to browse. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>How many times can I use the same discount? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Most of our discounts can be used an unlimited number of times,
                                however, some offers vary, Always check the eligibility of your
                                offer before using it. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>How do you redeem the codes? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>It’s simple. If you’re buying online just add the discount code at
                                the checkout. If you’re using your discount in-store, present your
                                code to the merchant when completing your purchase. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton> Can I cancel my subscription? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Absolutely. Our subscription works on a monthly rolling basis. This
                                means you can cancel your subscription whenever you want and still
                                enjoy your benefits for the remainder of the month. Please note that
                                we need 7 days’ notice to avoid your subscription renewing for the
                                following month. We don’t offer refunds on subscription payments
                                that have been received.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton> When does my subscription renew? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Your subscription will renew on the day of the month you signed up to
                                ARUZY. For example, if you signed up on the 5th of January, your
                                subscription would renew on the 5th of each month.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>What do I do if my discount doesn’t work? <img className={'accordionStatus'} src={imgAttrs.src} alt={imgAttrs.alt}/></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Our discounts should always work the first time. If you do have
                                any problems at the checkout, send us an email and we’ll find out what
                                the glitch is. Contact us at <a href={`mailto:${configData.company.email}`}>{configData.company.email} </a>and we’ll be back in
                                touch.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    )
}

export default FaqDetail