import * as React from "react"
import minusIcon from "../images/newimage/minus.png";
import plusIcon from "../images/newimage/plus.png";
import downArrow from "../images/newimage/down.png";
import upArrow from "../images/newimage/up.png";

const accordionIcons = (props, iconElem = 'img', iconType = 'symbol') => {
    Array.from(document.getElementsByClassName('accordion__panel')).forEach((element) => {
        setIconElement(element.previousElementSibling.getElementsByClassName('accordion__button')[0], iconElem, element.hasAttribute('hidden'), iconType);
    });
}

const setIconElement = (parent, iconElem, hidden, iconType) => {
    if (iconElem === 'img') {
        setAttributes(parent.getElementsByTagName(iconElem)[0], {
            src: iconType === 'arrow' ?
                (hidden ? downArrow : upArrow) :
                (hidden ? plusIcon : minusIcon), alt: 'Accordion Close'
        });
    } else {
        parent.getElementsByTagName(iconElem)[0].innerHTML = hidden ? '&#128899;' : '&#128897;';
    }
}

const setAttributes = (el, attrs) => {
    for (const key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
}

export default accordionIcons