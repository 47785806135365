import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import AruzySeo from "../components/aruzySeo"
import FaqDetail from "../components/FaqDetail"
import {Link} from "gatsby";
import HeaderTitle from "../components/HeaderTitle";

const PackagesPage = (props, data) => {
    const reccurance = {'Monthly': 'Month', 'Annual': 'Year'};

    function noResults() {
        return (<div className={'text-center pt-20'}><h4>No Packages Found!</h4></div>)
    }

    function renderDescription(descriptions) {
        return descriptions.map((description) => {
            return (<>
                <span key={description} className={'descriptionLine'}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
                    <defs>
                        <filter id="Rectangle_206">
                            <feOffset dy="3" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="3" result="blur"/>
                            <feFlood floodOpacity="0.161" result="color"/>
                            <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                            <feComposite operator="in" in="color"/>
                            <feComposite operator="in" in2="SourceGraphic"/>
                        </filter>
                        <filter id="Rectangle_205">
                            <feOffset dy="3" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="3" result="blur-2"/>
                            <feFlood floodOpacity="0.161" result="color-2"/>
                            <feComposite operator="out" in="SourceGraphic" in2="blur-2"/>
                            <feComposite operator="in" in="color-2"/>
                            <feComposite operator="in" in2="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g id="Group_370" data-name="Group 370" transform="translate(-46 -458)">
                        <g id="Group_403" data-name="Group 403">
                            <g data-type="innerShadowGroup">
                                <rect id="Rectangle_206-2" data-name="Rectangle 206" width="16" height="17" rx="5" transform="translate(46 458)" fill="#fff"/>
                                <g transform="matrix(1, 0, 0, 1, 46, 458)" filter="url(#Rectangle_206)">
                                    <rect id="Rectangle_206-3" data-name="Rectangle 206" width="16" height="17" rx="5" fill="#fff"/>
                                </g>
                                <g id="Rectangle_206-4" data-name="Rectangle 206" transform="translate(46 458)" fill="none" stroke="#97eaf6" strokeWidth="1">
                                    <rect width="16" height="17" rx="5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="15" height="16" rx="4.5" fill="none"/>
                                </g>
                            </g>
                            <g data-type="innerShadowGroup">
                                <rect id="Rectangle_205-2" data-name="Rectangle 205" width="10" height="10" rx="5" transform="translate(49 462)" fill="#74edf8"/>
                                <g transform="matrix(1, 0, 0, 1, 46, 458)" filter="url(#Rectangle_205)">
                                    <rect id="Rectangle_205-3" data-name="Rectangle 205" width="10" height="10" rx="5" transform="translate(3 4)" fill="#fff"/>
                                </g>
                                <g id="Rectangle_205-4" data-name="Rectangle 205" transform="translate(49 462)" fill="none" stroke="#97eaf6" strokeWidth="1">
                                    <rect width="10" height="10" rx="5" stroke="none"/>
                                    <rect x="0.5" y="0.5" width="9" height="9" rx="4.5" fill="none"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                    {description.trim()}
                </span><br/>
            </>);
        });
    }

    function renderPackage(pkg) {
        let descriptions = pkg.description != null ? pkg.description.split("<br>") : [];
        let lineLength = pkg.description != null ? pkg.description.split("<br>").length : 0;
        let price = pkg.price !== 0 ? ('£' + pkg.price + ' / ' + (pkg.limit > 1 ? pkg.limit + ' ' + pkg.interval + 's' : 'a' + ' ' + pkg.interval)) : 'Coming Soon';
        return (
            <div key={"blog-" + pkg.id} className={'col-md-4 voucherslisting packagelisting '}>
                <div className="blogtag">
                    <Link to={`/signup?package=${pkg.id}`}>
                        <img className="brandimage" alt="" src={pkg.image != null ? `${process.env.API_URL}` + pkg.image.url : 'https://via.placeholder.com/350x200.png?text=' + pkg.name}/>
                        <div className="card-body">
                            <h3 className="retailer">Our {pkg.name} comes with:</h3>
                            <p className="description mb-3" style={{WebkitLineClamp: lineLength}}>{pkg.description != null && pkg.description.trim() !== '' ? renderDescription(descriptions) : ""}</p>
                            <h6 className="pricetag">{price}</h6>
                            <div className="slide_from_left m-auto w-fit-content packageSignBtn">
                                <span className="btnicon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30.437" height="30.438"
                                     viewBox="0 0 30.437 30.438">
  <path id="Path_70" data-name="Path 70"
        d="M630.586,0A15.219,15.219,0,1,0,645.8,15.22,15.239,15.239,0,0,0,630.586,0M636.3,13.065l-5.506,5.507a1.376,1.376,0,0,1-.21.27,1.457,1.457,0,0,1-1.061.427l-.03,0a1.46,1.46,0,0,1-1.033-.429,1.558,1.558,0,0,1-.211-.27L625.276,15.6a1.461,1.461,0,0,1,2.066-2.066l2.181,2.18L634.237,11a1.459,1.459,0,1,1,2.064,2.064"
        transform="translate(-615.366)" fill="#fff"/>
</svg>
                            </span>
                                <span className="imagectabtnlable">Sign Up Now</span></div>
                        </div>
                    </Link>
                </div>
            </div>);
    }

    function renderResults() {
        if (props.pageContext.packages === false) {
            return 'loading..';
        }
        if (props.pageContext.packages.length === 0) {
            return noResults();
        }
        return props.pageContext.packages.sort((a, b) => a.node.id > b.node.id ? 1 : -1).map((pkg) => {
            return renderPackage(pkg.node);
        })
    }

    return (
        <main>
            <AruzySeo title={"Our Packages"}/>
            <Header/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 mb-30">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'OUR PACKAGES'}/>
                            </div>
                            <div className="row">{renderResults()}</div>
                        </div>
                        <div className="col-md-12 desktopView">
                            <div className="text-center">
                                <HeaderTitle title={"FAQ's"}/>
                            </div>
                            <FaqDetail/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>);
}

export default PackagesPage